
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import {Stack} from '@/models';

  @Component({
    components: {},
  })
  export default class AppReposPanel extends Vue {
    @Prop() public stack!: Stack;

    private newRepoName: string | null = null;

    private addRepo() {
      if (this.newRepoName) {
        this.stack.repos.push(this.newRepoName);
        this.newRepoName = null;
        this.$emit('edit-stack', this.stack);
      }
    }

    private removeRepo(repo: string) {
      const indexToDelete = this.stack.repos.findIndex((item: string) => item === repo);
      this.stack.repos.splice(indexToDelete, 1);
      this.$emit('edit-stack', this.stack);
    }
  }
