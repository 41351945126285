
  import {Component, Vue} from 'vue-property-decorator';
  import AppStacksPanel from '@/components/AppStacksPanel.vue';
  import AppReposPanel from '@/components/AppReposPanel.vue';
  import AppReposChart from '@/components/AppReposChart.vue';
  import Utils from '@/services/utils';
  import {Stack} from '@/models';

  @Component({
    components: {
      AppStacksPanel,
      AppReposPanel,
      AppReposChart,
    },
  })
  export default class AppIndex extends Vue {
    private selectedStack: Stack | null = null;

    get stacks() {
      return this.$store.state.stacks;
    }

    private async selectStack(stack: Stack) {
      this.selectedStack = stack;
    }

    private addStack(stackName: string) {
      const stack: Stack = {
        id: Utils.generateUuid(),
        name: stackName,
        repos: [],
      };
      this.$store.commit('addStack', {stack});
      this.selectedStack = stack;
    }

    private deleteStack(stack: Stack) {
      this.$store.commit('deleteStack', {stack});
      this.selectedStack = null;
    }

    private editStack(stack: Stack) {
      this.selectedStack = stack;
      this.$store.commit('editStack', {stack});
    }

    private mounted() {
      // @ts-ignore
      this.$ga.page({
        page: '/',
        title: 'Home page',
        location: window.location.href,
      });
    }
  }
