
  import {Component, Vue} from 'vue-property-decorator';
  import AppHeader from '@/components/layout/AppHeader.vue';
  import AppFooter from '@/components/layout/AppFooter.vue';
  import AppNotifications from '@/components/layout/AppNotifications.vue';

  @Component({
    components: {
      AppFooter,
      AppHeader,
      AppNotifications,
    },
  })
  export default class App extends Vue {
  }
