
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import {Sync} from 'vuex-pathify';
  import predefinedStacks from '@/data/stacks';
  import {Stack} from '@/models';

  @Component
  export default class AppStacksPanel extends Vue {
    @Prop() public readonly stacks!: Stack[];
    @Sync('showPredefined') private showPredefined!: boolean;

    private currIndex = -1;
    private search = '';
    private newStackName = null;

    private get filteredStacks() {
      const userStacks = this.stacks.filter((stack: Stack) => {
        return stack.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
      const predefined = predefinedStacks.filter((stack: Stack) => {
        return (stack.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.showPredefined);
      });
      return predefined.concat(userStacks);
    }

    private selectStackEvent(stack: Stack, index: number) {
      this.$emit('select-stack', stack);
      this.currIndex = index;
    }

    private deleteStack(stack: Stack) {
      this.$emit('delete-stack', stack);
    }

    private newStackEvent() {
      this.$emit('add-stack', this.newStackName);
      this.newStackName = null;
    }
  }
