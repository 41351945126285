
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {DateTime} from 'luxon';
import html2canvas from 'html2canvas';
import starHistoryService from '@/services/star-history.service';
import notificationService from '@/services/notification.service';
import firebase from 'firebase/app';
import 'firebase/database';
// @ts-ignore
import {encode} from 'firebase-encode';
import {FetchStarsError, Repository} from '@/models';
import _ from 'lodash';

const config = {
  apiKey: 'AIzaSyBPuZu6gbYL_IxW6UCYusHHrbzxHpsWNSE',
  authDomain: 'github-stars-history.firebaseapp.com',
  databaseURL: 'https://github-stars-history.firebaseio.com',
  projectId: 'github-stars-history',
  storageBucket: 'github-stars-history.appspot.com',
  messagingSenderId: '860963673180',
};
const firebaseApp = firebase.initializeApp(config);
const db = firebaseApp.database();
const firebaseReposRef = db.ref('repos_v3');

export interface LoadingItem {
  repoName: string;
  loading: boolean;
}

@Component({
  components: {},
})
export default class AppReposChart extends Vue {
  @Prop() public stackName!: string;
  @Prop() public repos!: string[];

  private reposData: Repository[] = [];
  private loaders: LoadingItem[] = [];
  private config: any = {
    tooltip: {
      xDateFormat: '%Y-%m-%d',
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
  };

  @Watch('repos')
  public onReposChanged() {
    this.reloadRepos();
  }

  private created() {
    this.reloadRepos();
  }

  private async reloadRepos() {
    this.reposData = [];
    this.loaders = [];

    this.repos.forEach((repoName) => {
      this.loaders.push({
        repoName,
        loading: true,
      });
      this.getRepoData(repoName)
        .then((repository: Repository) => {
          this.reposData.push(repository);
          if (repository.requiredCacheUpdate) {
            this.saveRepoToStore(repository);
          }
        })
        .catch((e: FetchStarsError) => {
          notificationService.error(e.statusText);
        })
        .finally(() => {
          _.remove(this.loaders, (li) => li.repoName === repoName);
          this.$forceUpdate();
        });
    });
  }

  private download() {
    // @ts-ignore
    html2canvas(this.$refs.chart.$el).then((canvas) => {
      const link = document.createElement('a');
      link.download = `${this.stackName || 'stars'}.png`;
      link.href = canvas.toDataURL();
      link.click();
    });
  }

  private getRepoData(repoName: string): Promise<Repository> {
    return new Promise<Repository>((resolve) => {
      firebaseReposRef.child(encode(repoName)).on('value', (snapshot) => {
        const repository: any = snapshot.val();
        if (!repository) {
          resolve(starHistoryService.getStarHistory(repoName));
        } else if (this.shouldRefreshRepo(repository)) {
          resolve(starHistoryService.fetchCurrentStars(repository));
        } else {
          resolve(repository);
        }
      });
    }).catch((e: FetchStarsError) => {
      return Promise.reject(e);
    });
  }

  private saveRepoToStore(repo: Repository) {
    repo.requiredCacheUpdate = false; // set to false before save to firebase
    firebaseReposRef.child(encode(repo.name)).set(repo);
  }

  private shouldRefreshRepo(repository: Repository) {
    const now = DateTime.utc();
    const lastRefreshDatePlusWeek = DateTime.fromISO(repository.lastRefreshDate).plus({days: 7});
    return now > lastRefreshDatePlusWeek;
  }
}
